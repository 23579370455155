.gameTable{
    display: flex;
}

.game{
    padding: 5px
}

.App{
    width: 100%;
}

#liveGame{
    vertical-align: center;
}

.gameButton{
    width: 40px;
    margin: 2px;
    text-align: center;
    padding: 0;
}

.gameCol{
    padding-left: 0;
}

.centered-tex{
    padding: 0 auto;
}

.center-parent{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.match-row{
    margin: 10px;
    width: 90%;
}

.name-row{
    max-width: 50%;
    width: 50%;
}

.match{
    padding-left: 25px;
}

.scroll-view{
    overflow-y: auto;
    max-height: 500px;
    max-width: 80%;
}

.match-col{
    width: 50%;
    max-width: 50%;
}

.lucky-loser-row{
    margin: 10px;
    width: 90%;
    height: 116px;
}

.lucky-loser-col{
    width: 50%;
    max-width: 50%;
}

#banner{
    position: sticky;
    bottom: 0;
}

.fixed-position{
    position: fixed;
}

.winner{
    color: darkgreen;
    font-weight: bold;
}

.loser{
    color: grey;
}

.isRegistered{
    font-weight:bold;
}